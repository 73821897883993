import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Hero from "../../components/hero/hero"
import RightCta from "../../components/cta/right-bordered/cta"
import LeftCta from "../../components/cta/left-bordered/cta"
import Savings from "../../components/savings/savings";


import desktopHero from "../../images/protect-against-another-heart-attack/oldMandesktop.png"
import mobileHero from "../../images/protect-against-another-heart-attack/oldManMobile.png"
import people from "../../images/protect-against-another-heart-attack/people.png"
import peopleMobile from "../../images/protect-against-another-heart-attack/people_mobile.png"
import handHeart from "../../images/protect-against-another-heart-attack/hand-heart-icon.png"
import handHeartMobile from "../../images/protect-against-another-heart-attack/hand-heart-icon-mobile.png"
import gradientOne from "../../images/protect-against-another-heart-attack/gradientOne.png"
import gradientTwo from "../../images/protect-against-another-heart-attack/gradientTwo.svg"
import ctaBackground from "../../images/protect-against-another-heart-attack/ctaBackground.svg"
import laptop from "../../images/protect-against-another-heart-attack/laptop_q.png"
import scope from "../../images/protect-against-another-heart-attack/scope.png"
import vascepaPill from "../../images/protect-against-another-heart-attack/04-no_handv4.png"
import vascepaPillMobile from "../../images/protect-against-another-heart-attack/04-no_handv4.png"


export default () => (
  <Layout>
    <SEO
      title="Heart Attack & Stroke Protection"
      keywords="Protect Against Another Heart Attack or Stroke"
      description="Up to 50% of people may have a CVD event or subsequent revascularization within the same year of an MI. Protect yourself against another heart attack or stroke with VASCEPA® (icosapent ethyl). Please see Indications and Important Safety Information."
       />

      <Hero
        desktopHeroBackground={desktopHero}
        mobileHeroBackground={mobileHero}
        imgBackground="protect-against"
        alt="A man, making a plan to protect his heart with VASCEPA® (icosapent ethyl)"
        headline={"If you have had a heart attack or stroke, the danger of suffering another one is even greater"}
        content={["Even if you are on a statin, you may not be as safe as you think. If you’ve suffered a heart attack, there’s an increased chance of another CV event within the year.",
        <br/>,<br/>,
        "For 25% additional protection after a heart attack or a stroke, talk to your doctor about taking VASCEPA with statins.* ",
        <br/>,<br/>,
        <p style={{ fontSize: "12px", lineHeight: "150%", textIndent: "-5px" }}>
        *In a clinical study, patients treated with VASCEPA and a statin had fewer cardiovascular events (17.2%) compared to those who took placebo (22%).
      </p>]}
        additionalClassName = "protect-hero-page"
        />

<LeftCta
          ctaBackground = {ctaBackground}
          colOne="6"
          colTwo="6"
          whiteborder="transparentborder"
          image={scope}
          mobileImage={scope}
          alt="Stethoscope icon"
          backgroundColor = "#E7E8EA"
          headline="Go beyond statins to protect your heart from another CV event."
          cta={["VASCEPA benefits ",  <span aria-hidden="true">&gt;</span>]}
          headlineColor = "#1D355E"
          ctaColor = "#1D355E"
          content={["Statins are recommended for high cholesterol, but even with well-controlled cholesterol, significant cardiovascular risk remains. Especially if you already had a heart attack or stroke. ",
          <br/>,<br/>,
          "Adding VASCEPA to a statin can help reduce this remaining risk."]}
          url="/benefits/"
          contentSubClass="left"
          ctaSubClass="left"
          imgSM="benefits-panel"
          containerClass="cta-with-content"
          contentHeadline="headline-benefits"
          />

      {<RightCta
        colOne="4"
        colTwo="8"
        whiteborder="whiteborder"
        image={handHeart}
        alt="Hand-heart icon"
        mobileImage={handHeartMobile}
        background={gradientOne}
        backgroundGradient="#01add6"
        headline="Starting to add heart-healthy habits to your routine? Adding VASCEPA can also help reduce your chances of suffering another heart attack or stroke."
        cta={["Learn more about protecting your heart ", <span aria-hidden="true">&gt;</span>]}
        url="https://vascepa.com/cardiovascular-risk/healthy-heart-tips"
        external="true"
        savingsCardImage="heart-hand-icon"
        mobileImageClass="img-fullheight"
        imgSM=""
        />
        }

      <LeftCta
          ctaBackground = {ctaBackground}
          colOne="6"
          colTwo="6"
          whiteborder="transparentborder"
          image={people}
          mobileImage={peopleMobile}
          imgSM="people-img mobile-height-160"
          alt="People icon"
          backgroundColor = "#E6E8EA"
          cta={["Download discussion guide ", <span aria-hidden="true">&gt;</span>]}
          headlineColor = "#1D355E"
          ctaColor = "#1D355E"
          url="https://amarincorp.com/docs/Vascepa-Downloadable-DDG-2020.pdf"
          contentSubClass="left"
          ctaSubClass="left"
          headline="After a first heart attack, around 20% of patients age 45 and older will have another heart attack within 5 years."
          content={["Ready to talk to your doctor about additional heart protection? "]}
          containerClass="cta-with-content"
          external="true"
          contentHeadline="download-discussion people-after"
          mobileImageClass="img-fullwidth"
          />
      <RightCta
          ctaBackground = {ctaBackground}
          colOne="5"
          colTwo="7"
          whiteborder="transparentborder"
          image={laptop}
          mobileImage={laptop}
          alt="Laptop icon"
          backgroundColor = "#E7E8EA"
          headline="Still haven’t taken the quiz yet?"
          cta={["Take the quiz ",  <span aria-hidden="true">&gt;</span>]}
          headlineColor = "#1D355E"
          ctaColor = "#1D355E"
          url="/cardiovascular-risk/quiz"
          contentSubClass="left"
          ctaSubClass="left"
          />

      <LeftCta
        colOne="7"
        colTwo="5"
        whiteborder="whiteborder"
        image={vascepaPill}
        mobileImage={vascepaPillMobile}
        alt="Clear VASCEPA® (icosapent ethyl) capsule"
        background={gradientTwo}
        backgroundGradient="#cec0dc"
        headline="There’s more you can do to protect your heart; start by adding VASCEPA."
        contentHeadline="fix-padding"
        cta={["See how VASCEPA works ", <span aria-hidden="true">&gt;</span>]}
        headlineColor = "#1D355E"
        ctaColor = "#1D355E"
        url="/about-vascepa/how-it-works"
        containerClass="cta-works"
        imgSM="bottle-img-container"
        imageLabel="Capsule is not actual size."
        />

        <div className="chatbot-spacer"></div>





  </Layout>
)
